import React, { useState } from 'react'
import Product from './Product'

const showItem = (start, i) => i < start + 3 && i > start - 1

export default function HomeCarrousel({ premios }) {
  const [itemsStart, setItemsStart] = useState(0)
  return (
    <section className="w-full bg-gray-200">
      <div className="w-full lg:w-4/6 mx-auto py-6">
        <h3 className="font-bold block text-pxblue-700 pb-4 text-3xl ml-2 lg:text-4xl lg:ml-16">
          Recomendaciones
        </h3>
        <div className="py-8">
          <div className="lg:flex lg:items-center">
            <button
              type="button"
              onClick={() =>
                setItemsStart(itemsStart - 1 < 0 ? 0 : itemsStart - 1)
              }
              className="hidden rounded-full p-4 h-4 w-4 bg-pxblue text-white justify-center items-center font-bold text-xl lg:flex hover:bg-pxblue-700"
            >
              &lt;
            </button>
            {premios.map((premio, i) => (
              <div
                key={`premiocarrousel-${premio.slug.current}`}
                className={`${
                  showItem(itemsStart, i) ? 'lg:block' : 'lg:hidden'
                } w-4/5 mx-auto lg:w-1/3 lg:mx-5 lg:my-0`}
              >
                <Product premio={premio} />
              </div>
            ))}
            <button
              type="button"
              onClick={() =>
                setItemsStart(
                  itemsStart + 1 < premios.length - 3
                    ? itemsStart + 1
                    : premios.length - 3
                )
              }
              className="hidden rounded-full p-4 h-4 w-4 bg-pxblue text-white justify-center items-center font-bold text-xl lg:flex hover:bg-pxblue-700"
            >
              &gt;
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

export default function HomeHero() {
  return (
    <section className="HomeHero relative top-0 w-full bg-blue-900">
      <div className="HomeHeroBg absolute top-0">
        <StaticImage
          src="../assets/home-hero.jpg"
          alt=""
          placeholder="blurred"
          className="w-full h-full object-fit"
        />
      </div>
      <div className="HomeHeroText lg:w-4/6 mx-auto relative top-0 z-10">
        <p className="text-2xl text-white pl-4 xl:pl-10 lg:text-4xl">
          <span className="font-bold block">Un programa diseñado para</span>
          recompensar tu esfuerzo
        </p>
      </div>
    </section>
  )
}

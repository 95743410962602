import React, { useContext, useEffect } from 'react'
import { graphql, Link } from 'gatsby'

import AppContext from '../components/AppContext'
import HomeHero from '../components/HomeHero'
import HomeCarrousel from '../components/HomeCarrousel'
import ProductsList from '../components/ProductsList'
import SEO from '../components/SEO'

import isAuthenticated from '../utils/isAuthenticated'
import { setRedirect } from '../utils/setRedirect'

async function checkTocken(cb) {
  const validToken = await isAuthenticated('user')
  cb(validToken)
}
const IndexMain = ({ recomendations, carrousel }) => (
  <>
    <HomeHero />
    <HomeCarrousel premios={carrousel} />
    <div className="flex justify-center my-10">
      <Link
        to="/catalogo"
        className="text-white bg-pxblue-700 rounded-2xl px-14 py-2 inline-block font-medium text-xl"
      >
        Ver catalogo completo
      </Link>
    </div>
    <section className="w-full md:w-4/5 xl:w-4/6 mx-auto">
      <div className="pb-12 flex flex-wrap items-center">
        {recomendations.length > 0 ? (
          <ProductsList premios={recomendations} />
        ) : (
          <p>No hay premios</p>
        )}
      </div>
    </section>
  </>
)
export default function IndexPage({ data: { site } }) {
  const { user } = useContext(AppContext)
  const [loggedUser, setLoggedUser] = user
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (loggedUser) {
        checkTocken(setLoggedUser)
      } else {
        // eslint-disable-next-line no-restricted-globals
        setRedirect(location ? location.href : '')
        // navigate('/login')
      }
    }
  })
  return (
    <main>
      <SEO title="" />
      {loggedUser ? (
        <IndexMain
          recomendations={site.nodes[0].recomendations}
          carrousel={site.nodes[0].carrousel}
        />
      ) : (
        <p className="w-11/12 mx-auto mt-10 md:w-1/2 lg:w-1/3 border-pxblue-600 border p-4 bg-gray-100 rounded-md text-pxtx-200 text-center">
          <Link to="/login" className="font-medium text-pxblue underline">
            Inicia sesión
          </Link>{' '}
          para ver el contenido
        </p>
      )}
    </main>
  )
}

export const query = graphql`
  query {
    site: allSanityStoreSettings {
      nodes {
        storeTitle
        carrousel {
          name
          description
          points
          slug {
            current
          }
          image {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        recomendations {
          name
          description
          points
          slug {
            current
          }
          image {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`
